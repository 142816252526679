.price_table{
  width: 100%;
  margin-bottom: 10px;
  tr{
    &:nth-child(odd){
      background-color: #fff;
    }
    &:nth-child(even){
      background-color: var(--Light-Gray);
    }
  }
  th{
    background: var(--Brand-Color-400);
    color: #fff;
    font-weight: 600;
  }
  td, th{
    vertical-align: middle;
    padding: 25px;
    line-height: 1.2;
    &:first-child{
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      white-space: normal!important;
    }
    &:last-of-type{
      text-align: right;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      white-space: nowrap;
    }
  }

  @include breakpoint(lg){
    td{
      padding: 15px 20px;
    }
  }
}