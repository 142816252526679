h1, .h1{
  font-size: 5.625rem;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.113rem;
  text-transform: uppercase;
  margin-bottom: 1.875rem;
  line-height: 1;
  @include breakpoint(xxl){
    font-size: 3.75rem;
  }
  @include breakpoint(xl){
    font-size: 3.125rem;
  }
  @include breakpoint(lg){
    font-size: 2.438rem;
  }
}

h2, .h2{
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.17;
  letter-spacing: -0.16rem;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  @include breakpoint(xxl){
    font-size: 3.125rem;
    letter-spacing: -0.08rem;
  }
  @include breakpoint(xl){
    font-size: 2rem;
    font-weight: 600;
  }
}

h3, .h3{
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.17;
  letter-spacing: -0.04rem;
  margin-bottom: 1.5rem;
  @include breakpoint(xl){
    font-size: 1.625rem;
  }
}

h4, .h4{
  font-size: 1.5rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03rem;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  @include breakpoint(xl){
    font-size: 1.25rem;
  }
}

.subtitle{
  font-size: 1.125rem;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.023rem;
  text-transform: uppercase;
  margin-bottom: 1.875rem;
  @include breakpoint(lg){
    font-size: 1rem;
    margin-bottom: 1.25rem;
  }
}