.button{
  display: inline-block;
  color: #fff!important;
  transition: .5s all ease;
  border-radius: 54px;
  background: var(--Brand-Color-400);
  padding: 20px;
  text-align: center;
  font-size: 1rem;
  font-weight: normal;
  line-height: normal;
  border: 0;
  outline: none;
  box-shadow: none;
  &:hover{
    background: var(--Dark-Brand);
  }
  &:visited{
    background: var(--Dark-Brand);
  }
}

.button_light{
  color: var(--Text-Color)!important;
  background-color: transparent;
  border: 1px solid var(--Text-Color);
  &:hover{
    color: #fff!important;
    border-color: var(--Dark-Brand);
    span{
      background: #fff;
      svg{
        path{
          fill:var(--Dark-Brand);
        }
      }
    }
  }
}

.button_arrow{
  display: flex;
  border: 0;
  outline: none;
  box-shadow: none;
  background: transparent;
  span{
    display: block;
    color: #fff!important;
    transition: .5s all ease;
    border-radius: 54px;
    background: var(--Brand-Color-400);
    padding: 20px;
    text-align: center;
    font-size: 1rem;
    font-weight: normal;
    line-height: normal;
    width: 100%;
  }
  .arrow{
    display: flex;
    width: 59px;
    min-width: 59px;
    height: 59px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    svg{
      width: 29px;
      height: 27px;
    }
  }
  &:hover{
    span{
      background: var(--Dark-Brand);
    }
  }
  &:visited{
    span{
      background: var(--Dark-Brand);
    }
  }
}

.load_more_btn{
  display: flex;
  align-items: center;
  justify-content: center;
  span{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: var(--Text-Color);
    margin-right: 10px;
    transition: .5s all ease;
    svg{
      width: 12px;
      height: 12px;
    }
  }
}