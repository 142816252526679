.team_list{
  margin-bottom: 65px;
  &__item{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border-radius: 20px;
    background: var(--Border-Color);
    border: 1px solid var(--Border-Color);
    overflow: hidden;
    max-width: 420px;
    margin: 0 auto 25px;
    &_photo{
      @include image-hover-transform;
      display: block;
      height: 408px;
      img{
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }
    &_desc{
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      border-radius: 0 0 20px 20px;
      background: var(--Light-Gray);
      padding: 30px 20px;
    }
    &_title{
      transition: .5s all ease;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 5px;
      &:hover{
        color: var(--Brand-Color-400);
      }
    }
    &_position{
      font-size: .9em;
      margin-bottom: 30px;
    }
    &_links{
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      margin-top: auto;
      //&:before{
      //  content: '';
      //  background: var(--Border-Color);
      //  position: absolute;
      //  left: 50%;
      //  top: 50%;
      //  transform: translateY(-50%);
      //  width: 1px;
      //  height: 46px;
      //}
    }
    &_link{
      display: flex;
      gap: 10px;
      align-items: center;
      line-height: 1;
      transition: .5s all ease;
      &:hover{
        opacity: .7;
      }
      span{
        max-width: 120px;
      }
      svg{
        width: 31px;
        height: 31px;
      }
    }
  }

  @include breakpoint(xxl){
    margin-bottom: 20px;
    &__item{
      margin-bottom: 20px;
      &_photo{
        height: 362px;
      }
      &_desc{
        padding: 30px 15px;
      }
      &_position{
        margin-bottom: 20px;
      }
      &_link{
        font-size: 14px;
        span{
          max-width: 95px;
        }
      }
    }
  }
}

.team_section{
  display: flex;
  flex-direction: column;
  padding-bottom: 180px;
  .load_more_btn{
    margin: 0 auto;
    width: 360px;
    max-width: 100%;
  }

  @include breakpoint(xl){
    padding-bottom: 80px;
  }
}




