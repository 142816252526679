.infinite_repeat_text {
  position: relative;
  overflow: hidden;
  --offset: 20vw;
  --move-initial: calc(-25% + var(--offset));
  --move-final: calc(-50% + var(--offset));
  z-index: 1;
  width: 200%;
  font-size: 32px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__inner{
    display: flex;
    gap: 20px;
    position: relative;
    animation: infinite_repeat_text 15s linear infinite;
    width: 100%;
  }
  span {
    white-space: nowrap;
  }

  @include breakpoint(xxl){
    height: 76px;
    font-size: 22px;
  }
}

@keyframes infinite_repeat_text {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}