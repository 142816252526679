.page_title{
  padding-top: 100px;
  padding-bottom: 60px;
  &__title{
    max-width: 605px;
  }
  &__description{
    max-width: 416px;
  }
  &__subtitle{
    text-align: right;
    max-width: 280px;
  }

  @include breakpoint(lg){
    padding-top: 40px;
    padding-bottom: 60px;
    &__subtitle{
      max-width: 208px;
    }
  }
}