.services_section{
  padding: 120px 0;
  .services_list{
    padding-top: 40px;
    &__item{
      margin-bottom: 25px;
    }
    &__link{
      min-height: 276px;
    }
  }

  @include breakpoint(md){
    padding: 80px 0;
    .services_list{
      padding-top: 10px;
      &__item{
        margin-bottom: 10px;
      }
    }
  }
}

.junior_section{
  padding-top: 120px;
  padding-bottom: 180px;
  &__title{
    max-width: 534px;
  }
  &__desc{
    max-width: 339px;
  }
  &__button{
    width: 361px;
    max-width: 100%;
  }
  @include breakpoint(xl){
    padding-top: 100px;
    padding-bottom: 80px;
  }
  @include breakpoint(md){
    padding-top: 80px;
    padding-bottom: 60px;
  }
}

.services_advantages{
  padding-bottom: 180px;
  &__title{
    margin-bottom: 80px;
  }

  @include breakpoint(xl){
    padding-bottom: 120px;
    &__title{
      max-width: 840px;
    }
  }

  @include breakpoint(xl){
    padding-bottom: 80px;
    .advantages_slider{
      margin:0 calc(var(--bs-gutter-x)* (-.5));
      width: calc(100% + var(--bs-gutter-x));
      padding: 0 calc(var(--bs-gutter-x)* .5);
    }
    .advantages_list{
      &__item{
        max-width: 411px;
        min-height: 245px;
      }
    }
  }

  @include breakpoint(md){
    .advantages_list{
      &__item{
        max-width: 348px;
      }
    }
  }
}