@import "../fonts/WixMadeforDisplay/stylesheet.css";

//@font-face {
//  font-family: 'Wix Madefor Display';
//  src: url('../../dist/fonts/WixMadeforDisplay/WixMadeforDisplay-Regular.eot');
//  src: local('Wix Madefor Display Regular'), local('WixMadeforDisplay-Regular'),
//  url('../../dist/fonts/WixMadeforDisplay/WixMadeforDisplay-Regular.eot?#iefix') format('embedded-opentype'),
//  url('../../dist/fonts/WixMadeforDisplay/WixMadeforDisplay-Regular.woff2') format('woff2'),
//  url('../../dist/fonts/WixMadeforDisplay/WixMadeforDisplay-Regular.woff') format('woff'),
//  url('../../dist/fonts/WixMadeforDisplay/WixMadeforDisplay-Regular.ttf') format('truetype');
//  font-weight: normal;
//  font-style: normal;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Wix Madefor Display';
//  src: url('../../dist/fonts/WixMadeforDisplay/WixMadeforDisplay-Medium.eot');
//  src: local('Wix Madefor Display Medium'), local('WixMadeforDisplay-Medium'),
//  url('../../dist/fonts/WixMadeforDisplay/WixMadeforDisplay-Medium.eot?#iefix') format('embedded-opentype'),
//  url('../../dist/fonts/WixMadeforDisplay/WixMadeforDisplay-Medium.woff2') format('woff2'),
//  url('../../dist/fonts/WixMadeforDisplay/WixMadeforDisplay-Medium.woff') format('woff'),
//  url('../../dist/fonts/WixMadeforDisplay/WixMadeforDisplay-Medium.ttf') format('truetype');
//  font-weight: 500;
//  font-style: normal;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Wix Madefor Display';
//  src: url('../../dist/fonts/WixMadeforDisplay/WixMadeforDisplay-Bold.eot');
//  src: local('Wix Madefor Display Bold'), local('WixMadeforDisplay-Bold'),
//  url('../../dist/fonts/WixMadeforDisplay/WixMadeforDisplay-Bold.eot?#iefix') format('embedded-opentype'),
//  url('../../dist/fonts/WixMadeforDisplay/WixMadeforDisplay-Bold.woff2') format('woff2'),
//  url('../../dist/fonts/WixMadeforDisplay/WixMadeforDisplay-Bold.woff') format('woff'),
//  url('../../dist/fonts/WixMadeforDisplay/WixMadeforDisplay-Bold.ttf') format('truetype');
//  font-weight: bold;
//  font-style: normal;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Wix Madefor Display';
//  src: url('../../dist/fonts/WixMadeforDisplay/WixMadeforDisplay-ExtraBold.eot');
//  src: local('Wix Madefor Display ExtraBold'), local('WixMadeforDisplay-ExtraBold'),
//  url('../../dist/fonts/WixMadeforDisplay/WixMadeforDisplay-ExtraBold.eot?#iefix') format('embedded-opentype'),
//  url('../../dist/fonts/WixMadeforDisplay/WixMadeforDisplay-ExtraBold.woff2') format('woff2'),
//  url('../../dist/fonts/WixMadeforDisplay/WixMadeforDisplay-ExtraBold.woff') format('woff'),
//  url('../../dist/fonts/WixMadeforDisplay/WixMadeforDisplay-ExtraBold.ttf') format('truetype');
//  font-weight: bold;
//  font-style: normal;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Wix Madefor Display';
//  src: url('../../dist/fonts/WixMadeforDisplay/WixMadeforDisplay-SemiBold.eot');
//  src: local('Wix Madefor Display SemiBold'), local('WixMadeforDisplay-SemiBold'),
//  url('../../dist/fonts/WixMadeforDisplay/WixMadeforDisplay-SemiBold.eot?#iefix') format('embedded-opentype'),
//  url('../../dist/fonts/WixMadeforDisplay/WixMadeforDisplay-SemiBold.woff2') format('woff2'),
//  url('../../dist/fonts/WixMadeforDisplay/WixMadeforDisplay-SemiBold.woff') format('woff'),
//  url('../../dist/fonts/WixMadeforDisplay/WixMadeforDisplay-SemiBold.ttf') format('truetype');
//  font-weight: 600;
//  font-style: normal;
//  font-display: swap;
//}

