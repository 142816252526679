.js_nav_open {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background: var(--Text-Color);
  z-index: 999;
  transition: left 0.5s ease;
}

.hidden_body {
  overflow: hidden;
}

.header{
  &__content{
    border-bottom: 1px solid var(--Border-Color);
    background: #fff;
    position: relative;
    z-index: 2;
    &_row{
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 126px;
    }
  }
  &__contacts{
    margin-left: auto;
    &_title{
      display: none;
    }
    &_row{
      display: flex;
      align-items: center;
      gap: 50px;
    }
    &_address{
      font-size: 14px;
    }
  }
  .navbar_toggler{
    width: 32px;
    height: 32px;
    display: none;
    margin-left: 40px;
  }
  &__logo{

  }
  &__nav{
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    position: relative;
    z-index: 2;
    &_title{
      display: none;
    }
    &_close{
      position: absolute;
      top:30px;
      right: 20px;
      cursor: pointer;
    }
    &_list{
      display: flex;
      align-items: center;
      margin: 0 -25px;
      gap: 30px;
      margin-right: auto;
      li{
        a{
          border-radius: 69px;
          background: transparent;
          padding: 15px 25px;
          text-align: center;
          transition: .5s all ease;
          &:hover, &.active{
            background: var(--Light-Gray);
          }
        }
      }
    }
    &_button{
      margin-left: 90px;
    }
  }
  @include breakpoint(xxl){
    &__content{
      &_row{
        min-height: 90px;
      }
    }
    &__nav{
      &_list{
        margin: 0 -20px;
        gap: 10px;
        li{
          a{
            border-radius: 30px;
            padding: 10px 20px;
          }
        }
      }
      &_button{
        margin-left: 20px;
      }
    }
  }

  .lang_mobile{
    display: none;
  }

  @include breakpoint(xl){
    .navbar_toggler{
      display: block;
    }
    &__nav {
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: 9999;
      transition: right .3s ease;
      right: -100%;
      top: 0;
      max-width: 320px;
      background: #FFF;
      box-shadow: 20px 4px 30px 0 rgba(0, 0, 0, 0.25);
      overflow-y: auto;
      scrollbar-color: transparent transparent;
      scrollbar-width: thin;
      padding: 0 20px 30px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      &.active {
        right: 0;
      }
      &::-webkit-scrollbar {
        width: 2px;
      }
      &::-webkit-scrollbar-track {
        display: none;
      }
      &_title{
        display: block;
        padding: 33px 0;
        width: 100%;
      }
      &_list{
        width: 100%;
        display: block;
        align-items: center;
        margin: 0 0 30px;
        padding-bottom: 15px;
        border-bottom: 1px solid var(--Border-Color);
        li{
          a{
            border-radius:0;
            background: transparent!important;
            padding: 10px 0;
            text-align: left;
          }
        }
      }
      &_button{
        margin: 0 0 40px;
      }
    }
    #lang{
      display: none;
    }
    .lang_mobile{
      display: flex;
    }
  }

  @include breakpoint(lg){
    .navbar_toggler{
      margin-left: 0;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
    &__content{
      &_row{
        min-height: 70px;
      }
    }
    &__contacts{
      background: #FFF;
      padding: 10px;
      width: 190px;
      position: relative;
      &:hover{
        .header__contacts_dropdown{
          display: block;
        }
      }
      &_title{
        display: block;
        border-radius: 20px 20px 0 0;
      }
      &_dropdown{
        display: none;
        position: absolute;
        left: 0;
        padding: 15px 20px 20px;
        border-radius: 0 0 20px 20px;
        background: #FFF;
        width: 190px;
        border-top: 1px solid var(--Border-Color);
        margin-top: 15px;
      }
      &_row{
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        font-size: 14px;
        .icon{
          display: none;
        }
      }
      &_address{
        font-size: 12px;
      }
    }
  }

}


#lang{
  border-radius: 66px;
  border: 1px solid var(--Border-Color);
  padding: 20px;
  height: 60px;
  width: 96px;
  box-shadow: none;
  outline: none;
  background: url("../../assets/images/arrow-up.svg")no-repeat 80% 60% #fff;
  background-size: 16px 16px;
  margin-left: auto;
}

.lang_mobile{
  align-items: center;
  gap: 20px;
  display: none;
  li{
    a:hover, p{
      color: var(--Text-Color);
    }
    a{
      color: var(--Secondary-Text-Color);
    }
  }
}