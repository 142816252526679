.certificate_slider{
  margin-top: 46px;
  .swiper-wrapper {
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
  }
  .swiper-slide{
    max-width: 410px;
    a{
      display: block;
    }
    img{
      display: block;
      width: 100%;
    }
  }
  @include breakpoint(lg){
    margin-top: 26px;
    .swiper-slide{
      max-width: 348px;
    }
  }
}