.gallery_slider{
  padding-top: 140px;
  margin-top: -60px;
  .swiper__nav{
    position: absolute;
    top: 0;
    right: 0;
  }
  &__title{
    max-width: 890px!important;
    margin-bottom: 0;
    position: relative;
    z-index: 2;
  }
  .gallery_slide{
    border-radius: 30px;
    border: 1px solid var(--Border-Color);
    background: var(--Light-Gray);
    padding: 30px 30px 30px 60px;
    font-size: .9em;
    width: 100%;
    &__info{
      display: flex;
      gap: 20px;
      margin: auto 0;
      flex-direction: column;
      &_item{
        border-radius: 16px;
        border: 1px solid var(--Text-Color);
        padding: 20px 25px;
        display: flex;
        align-items: center;
        gap: 10px;
        max-width: 290px;
      }
    }
    &__photo{
      gap: 25px 0;
      display: flex;
      .img_col{
        position: relative;
      }
      img{
        border-radius: 10px;
        display: block;
        width: 100%;
      }
    }
    &__before{
      border-radius: 40px;
      border: 1px solid var(--Border-Color);
      background: #FFF;
      position: absolute;
      top: 20px;
      left: 30px;
      font-weight: bold;
      padding: 9px 20px;
    }
  }

  .gallery_child_slider{
    display: none;
    width: 308px;
    margin: 0 auto;
    .swiper-wrapper{
      height: auto;
    }
    .child_slide{
      position: relative;
      .gallery_slide__before{
        left: 20px;
      }
      img{
        border-radius: 10px;
        display: block;
        width: 100%;
      }
    }
  }

  @include breakpoint(xxl){
    .gallery_slide{
      padding: 30px 20px;
      &__info{
        gap: 10px;
        &_item{
          padding: 15px;
          gap: 8px;
        }
      }
    }
  }

  @include breakpoint(xl){
    &__title{
      max-width: 520px!important;
    }
  }

  @include breakpoint(lg){
    padding-top: 40px;
    margin-top: 0;
    .swiper__nav{
      position: relative;
      top: auto;
      right: auto;
      margin-top: 20px;
    }
    .gallery_child_slider{
      display: block;
    }
    .gallery_slide{
      padding: 36px 15px 20px;
      &__photo{
        display: none;
      }
      &__info{
        margin: 0 0 40px;
      }
    }
  }

  @include breakpoint(xs){
    .gallery_child_slider{
      width: 250px;
    }
  }

}