.form_section{
  min-height: 735px;
  padding: 80px 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.testimonials_form{
    padding-bottom: 180px;
  }
  &__title{
    max-width: 690px;
  }
  .form{
    margin: 40px 0;
  }
  @include breakpoint(xxl){
    min-height: 620px;
    &.testimonials_form{
      padding-bottom: 100px;
    }
    &__title{
      font-size: 44px!important;
      max-width: 540px;
    }
  }
  @include breakpoint(xl){
    &__row{
      max-width: 740px;
      margin: 0 auto;
    }
  }
  @include breakpoint(md){
    &.testimonials_form{
      padding-bottom: 56px;
    }
    &__title{
      font-size: 32px!important;
    }
  }
}