.testimonials_page{
  padding-bottom: 180px;
  display: flex;
  flex-direction: column;
  .swiper-initialized{
    .testimonials_list{
      display: flex;
    }
  }
  .swiper-pagination{
    display: none;
  }
  .testimonials_list{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
    &__item{
      .inner_wrap{
        border-radius: 30px;
        border: 1px solid var(--Border-Color);
        background: var(--Light-Gray);
        min-height: 357px;
      }
    }
  }
  .load_more_btn{
    margin: 80px auto 0;
    display: none;
    width: 360px;
  }

  @include breakpoint(xl){
    padding-bottom: 80px;
    .testimonials_list{
      gap: 15px;
    }
  }

  @include breakpoint(md){
    .services_slider{
      margin:0 calc(var(--bs-gutter-x)* (-.5));
      width: calc(100% + var(--bs-gutter-x));
      padding: 0 calc(var(--bs-gutter-x)* .5);
    }
    .testimonials_list{
      display: flex;
      gap: initial;
      &__item{
        max-width: 348px;
        width: 100%;
      }
      &__link{
        min-height: 220px;
      }
    }
    .swiper-pagination{
      display: block;
    }
  }

  @media (min-width: 768px){
    .testimonials_list{
      transform: none!important;
    }
    .load_more_btn{
      display: flex;
    }
  }
}