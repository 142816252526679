.form{
  &__fildset{
    margin-top: 20px;
  }
  &__label{
    margin-bottom: 10px;
    display: block;
  }
  &__input{
    display: block;
    width: 100%;
    padding: 20px 30px;
    border-radius: 54px;
    border: 1px solid var(--Border-Color);
    background: #fff;
    color: var(--Secondary-Text-Color);
    &:focus{
      border-color: var(--Brand-Color-400);
    }
  }
  textarea{
    height: 198px;
    border-radius: 20px!important;
  }
  &__select{
    display: block;
    width: 100%;
    box-shadow: none!important;
    outline: none!important;
    background: url("../../assets/images/arrow-up.svg")no-repeat center right #fff;
    background-size: 16px 16px;
  }
  .button_arrow{
    margin-top: 40px;
    width: 100%;
  }
  .button{
    width: 100%;
  }

  @include breakpoint(sm){
    &__input{
      padding: 20px;
    }
  }
}

.price_search_form{
  margin-bottom: 40px;
  .input_search{
    background: url("../../assets/images/search.svg")no-repeat 30px center #fff;
    background-size: 24px 24px;
    padding-left: 64px;
  }
  .button{
    width: 188px;
    margin-left: -100px;
    .icon{
      display: none;
    }
  }

  @include breakpoint(sm){
    .input_search{
      background: #fff;
      padding-left: 20px;
    }
    .button{
      width: 95px;
      margin-left: -60px;
      span{
        display: none;
      }
      .icon{
        display: block;
        margin: 0 auto;
      }
    }
  }
}

.form_team_sort{
  margin-bottom: 60px;
  .form__select{
    padding: 20px;
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid var(--Border-Color);
    color: var(--Secondary-Text-Color);
  }
}

.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
  background: rgba(20, 4, 29, 0.70);
}

.swal2-popup{
  background: var(--Light-Gray);
  width: 1200px;
  @include breakpoint(xxl){
    width: 840px;
  }
}

.swal2-close{
  color: var(--Text-Color);
}

.swal_inner{
  max-width: 750px;
  margin: 0 auto;
  color: var(--Text-Color);
  text-align: left;
  padding: 110px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 690px;
  .form{
    .button{
      margin-top: 40px;
    }
  }
  @include breakpoint(xxl){
    padding: 80px 0;
    min-height: 400px;
    max-width: 650px;
  }
  @include breakpoint(lg){
    padding: 50px 0;
  }
}

.swal2-content{
  padding: 0;
  font-size: .9em;
}

