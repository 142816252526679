.advantages_list{
  &__item{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    &:nth-child(4n+2){
      .inner_wrap{
        background-color: var(--Brand-Pink-Light);
        color: var(--Dark-Pink);
      }
    }
    &:nth-child(4n+3){
      .inner_wrap{
        background-color: var(--Brand-Green-Light);
        color: var(--Dark-Green);
      }
    }
    &:nth-child(4n+4){
      .inner_wrap{
        background-color: var(--Brand-Color-100);
        color: var(--Dark-Brand);
      }
    }
    .inner_wrap{
      padding: 35px 30px;
      border-radius: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
	  background-color: var(--Brand-Blue-Light);
	  color: var(--Dark-Blue);
    }
    .item_title{
      color: var(--Text-Color);
    }
    .num{
      font-size: 16px;
      padding-bottom: 15px;
    }
  }

  @include breakpoint(md){
    &__item{
      .inner_wrap{
        border-radius: 1rem;
        padding:2rem 1rem;
      }
    }
  }
}