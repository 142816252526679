.single_team_title{
  padding: 120px 0 180px;
  &__img{
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    object-position: top;
    border-radius: 20px;
  }
  &__desc{
    width: 100%;
    border-radius: 20px;
    border: 1px solid var(--Border-Color);
    background: var(--Light-Gray);
    padding: 90px 60px;
    display: flex;
    flex-direction: column;
    a.subtitle:hover{
      color: var(--Dark-Brand);
    }
    .button{
      margin-top: auto;
      width: 100%;
    }
  }

  @include breakpoint(xxl){
    padding: 100px 0;
    &__desc{
      padding: 40px 30px;
    }
    &__img{
      max-width: 740px;
      margin: 0 auto;
    }
  }

  @include breakpoint(md){
    padding: 20px 0 80px;
    &__desc{
      padding: 40px 20px;
      .h3{
        font-size: 20px;
      }
    }
  }
}

.team_certificate_slider{
  margin-bottom: 180px;
  .swiper-button-prev, .swiper-button-next{
    width: 32px;
    height: 32px;
    z-index: 99;
    &:after{
      width: 16px;
      height: 16px;
    }
  }
  .swiper-slide{
    a{
      display: block;
      width: 100%;
    }
    img{
      display: block;
      width: 100%;
    }
  }
  @include breakpoint(xl){
    margin-left: calc(var(--bs-gutter-x)* (-.5));
    margin-right: calc(var(--bs-gutter-x)* (-.5));
    width: calc(100% + var(--bs-gutter-x));
    padding: 0 calc(var(--bs-gutter-x)* .5);
    margin-bottom: 100px;
    .swiper-slide{
      max-width: 350px;
    }
  }
}