.circle_list{
  .item{
    padding-left: 36px;
    margin-bottom: 20px;
    position: relative;
    &:nth-child(4n+2){
      &:before{
        background-color: var(--Brand-Blue);
      }
    }
    &:nth-child(4n+3){
      &:before{
        background-color: var(--Brand-Green);
      }
    }
    &:nth-child(4n+4){
      &:before{
        background-color: var(--Brand-Pink);
      }
    }
    &:before{
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      display: block;
      border-radius: 50%;
      background-color: var(--Brand-Color);
      top: 3px;
      left: 0;
    }
    &:after{
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: #fff;
      border-radius: 50%;
      top: 7px;
      left: 4px;
    }
    .secondary_text_color{
      font-size: .9em;
      margin-top: 5px;
    }
  }

  @include breakpoint(sm){
    .item{
      padding-left: 26px;
      &:before{
        width: 14px;
        height: 14px;
      }
      &:after{
        width: 6px;
        height: 6px;
        top: 7px;
        left: 4px;
      }
    }
  }
}