.social{
  display: flex;
  align-items: center;
  gap: 20px;
  a{
    transition: .5s all ease;
    &:hover{
      opacity: .6;
    }
  }
  @include breakpoint(sm){
    gap: 10px;
  }
}