.price_section{
  padding: 120px 0 180px;
  @include breakpoint(xxl){
    padding: 100px 0;
  }
  @include breakpoint(lg){
    padding: 60px 0 80px;
  }
}

.price_accordion {
  .accordion-toggle {
    padding:25px 70px 25px 25px;
    border-radius: 15px;
    background: var(--Border-Color);
    margin-bottom: 10px;
    transition: .5s all ease;
    .icon{
      content: '';
      width: 32px;
      height: 32px;
      border-radius: 50%;
      position: absolute;
      right: 25px;
      top: 50%;
      transform: rotate(0) translateY(-50%);
      background:var(--Brand-Color-400);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      transition: .5s all ease;
      svg {
        width: 16px;
        height: 16px;
      }
    }
    &:hover, &.active{
      background: var(--Brand-Color-400);
      color: #fff;
      .icon{
        transform: rotate(90deg) translate(-50%, 0);
        background:#fff;
        svg{
          path{
            fill:var(--Brand-Color-400);
          }
        }
      }
    }
  }

  @include breakpoint(lg){
    .accordion-toggle {
      padding: 15px 60px 15px 20px;
      .icon{
        width: 24px;
        height: 24px;
        right: 20px;
        svg{
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}

