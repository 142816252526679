.post_content {
  position: relative;
  color: var(--Secondary-Text-Color);

  h1, h2, h3, h4 {
    color: var(--Text-Color);
  }

  a {
    text-decoration: underline;
    transition: 0.5s ease;

    &:hover {
      color: var(--Brand-Color-400);
    }
  }

  ul {
    list-style: disc;
    margin-left: 20px;

    li {
      position: relative;
      line-height: 1.5;
    }
  }

  ol {
    counter-reset: item;

    & > li {
      position: relative;
      padding: 5px 0;
      line-height: 1.5;

      &::before {
        content: counters(item, ".") ". ";
        counter-increment: item;
        display: inline-block;
        margin-right: 0.5em;
        text-align: right;
      }
    }
  }

  blockquote {
    font-size: 1.2rem;
    margin: 20px 0;
    line-height: 1.3;
    position: relative;
    padding: 10px 0 10px 20px;
    border-left: 5px solid var(--Brand-Color-400);

    p {
      padding: 0;
    }
  }

  .img {
    display: block;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  table {
    width: 100%
  }

  &.article_post {
    p {
      margin-bottom: 1.5rem;
    }

    figure {
      width: 100% !important;

      img {
        width: 100% !important;
        height: auto !important;
      }
    }
  }
}