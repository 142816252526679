.contacts_list{
  margin-bottom: 160px;
  &__item{
    margin-bottom: 40px;
    &_map{
      iframe{
        height: 100%;
        border-radius: 20px;
        min-height: 300px;
      }
    }
    &_content{
      border-radius: 20px;
      border: 1px solid var(--Border-Color);
      background: var(--Light-Gray);
      padding: 60px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      .button_arrow{
        width: 100%;
        margin-top: auto;
      }
    }
  }

  @include breakpoint(xxl){
    margin-bottom: 80px;
    &__item{
      &_content{
        padding: 40px 20px;
      }
    }
  }
}

