.services_list {
  &__item {
    display: flex;
    flex-direction: column;

    &:nth-child(4n+2) {
      .services_list__link .point {
        background-color: var(--Brand-Blue);
      }
    }

    &:nth-child(3n+4) {
      .services_list__link .point {
        background-color: var(--Brand-Green);
      }
    }

    &:nth-child(3n+3) {
      .services_list__link .point {
        background-color: var(--Brand-Pink);
      }
    }
  }

  &__link {
    border-radius: 16px;
    border: 1px solid var(--Border-Color);
    background: #FFF;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px;
    min-height: 177px;

    .point {
      width: 24px;
      height: 24px;
      display: block;
      border-radius: 50%;
      position: relative;
      background-color: var(--Brand-Color);

      &:after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: #fff;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .post_content {
      a {
        text-decoration: none;
        color: var(--Secondary-Text-Color);

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &:hover {
      background-color: var(--Brand-Color-400);
      color: #fff;

      a {
        color: #fff;
      }

      .secondary_text_color {
        color: #fff;
      }

      .post_content {
        color: #fff;
      }

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  @include breakpoint(xxl) {
    .post_content {
      font-size: .85em;
    }
  }
}