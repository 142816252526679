.hero_section{
  min-height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-top: -100px;
  &__container{
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  &__img{
    position: absolute;
    left: 50%;
    top: 0;
    height: 100vh;
  }
  &__desc{
    width: 48%;
    padding: 120px 0 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }
  &__subtitle{
    max-width: 470px;
  }
  .rotate_icon{
    width: 66px;
    height: 66px;
    margin-top: -10px;
    svg{
      width: 40px;
      height: 40px;
    }
  }
  .button_arrow{
    max-width: 332px;
    margin-top: 60px;
  }

  @include breakpoint(xl){
    margin-top: 0;
    &__img{
      left: 55%;
    }
    &__desc{
      width: 55%;
    }
    .rotate_icon{
      width: 46px;
      height: 46px;
      svg{
        width: 36px;
        height: 36px;
      }
    }
  }

  @include breakpoint(lg){
    margin-top: -20px;
    min-height: auto;
    &__container{
      padding-top: 220px;
      max-width: 740px;
    }
    &__img{
      height: 350px;
    }
    &__desc{
      width: 100%;
      padding: 20px 0;
      h1{
        max-width: 500px;
      }
    }
    .button_arrow{
      margin-top: 40px;
    }
  }

  @include breakpoint(sm){
    &__container{
      padding-top:0;
    }
    &__img{
      position: relative;
      left: auto;
      right: auto;
      top: auto;
      margin:0 calc(var(--bs-gutter-x)* (-.5));
      width: calc(100% + var(--bs-gutter-x));
      height: auto;
    }
  }
}

/*------------------------*/
.index_description{
  padding: 180px 0 220px;
  &__title{
    display: inline-block;
    font-size: 18px;
    text-transform: uppercase;
    padding: 10px 15px;
    border-radius: 8px;
    margin-bottom: 39px;
    font-weight: 600;
  }
  &__mission{
    max-width: 193px;
  }
  @include breakpoint(xxl){
    padding: 150px 0;
  }
  @include breakpoint(lg){
    padding: 100px 0;
  }
  @include breakpoint(sm){
    padding: 80px 0;
    &__mission{
      margin-left: auto;
    }
  }
}

.animation_text{
  font-size: 124px;
  font-style: normal;
  font-weight: 800;
  line-height: 1.1;
  letter-spacing: -2.48px;
  text-transform: uppercase;
  color: var(--Border-Color);
  margin-bottom: 60px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap:0 1rem;
  & > div{
    position: relative;
  }
  span{
    position: absolute;
    width: 124px;
    height: 100%;
    color: var(--Text-Color);
    left: 0;
    top:0;
    overflow:hidden;
  }
  @include breakpoint(xxl){
    font-size: 92px;
  }
  @include breakpoint(xl){
    font-size: 60px;
  }
  @include breakpoint(md){
    font-size: 49px;
  }
}

/*------------------------*/
.index_advantages{
  position: relative;
  padding: 100vh 0 50vh;
  min-height: 100vh;
  &__bg{
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    &_wrap{
      height: 100vh;
      padding-top: 126px;
      background: url("../../assets/images/bg1.jpg")no-repeat center;
      background-size: cover;
      position: sticky;
      //top: calc(50vh - 100px);
      top: 0;
    }
    .container{
      position: sticky;
      left: 0;
      right: 0;
      top: 126px;
      padding-bottom: 126px;
    }
  }
  .advantages_list{
    position: relative;
    display: flex;
    flex-direction: column;
    &__item{
      width: 409px;
      max-width: 100%;
      .inner_wrap{
        min-height: 529px;
      }
      &:nth-child(1){

      }
      &:nth-child(2){
        margin-left: 50%;
        margin-top: -800px;
        margin-bottom: 140px;
      }
      &:nth-child(3){
        margin-left: auto;
      }
      &:nth-child(4){
        margin-left: 25%;
        margin-top: -217px;
      }
    }
  }

  @include breakpoint(xl){
    &__bg{
      &_wrap{
        padding-top: 60px;
      }
    }
    .advantages_list{
      max-width: 840px;
      margin: 0 auto;
      &__item{
        margin-bottom: 20px!important;
        margin-top: 0!important;
        &:nth-child(1){

        }
        &:nth-child(2){
          margin-left: auto;
        }
        &:nth-child(3){
          margin-left: 0;
        }
        &:nth-child(4){
          margin-left: auto;
        }
      }
    }
  }

  @include breakpoint(lg){
    padding: 3rem 0 1.2rem;
    background: url("../../assets/images/bg1.jpg")no-repeat top;
    background-size: 100%;
    &__bg{
      position: relative;
      z-index: auto;
      &_wrap{
        padding-top:0;
        height: auto;
        background: none;
        .container{
          position: relative;
          left: auto;
          right: auto;
          top: auto;
          padding-bottom: 0;
        }
      }
    }
      .advantages_list {
        &__item {
          .inner_wrap {
            min-height: 454px;
          }
        }
      }
  }
}

/*------------------------*/
.index_services{
  padding: 180px 0;
  &__grid{
    display: grid;
    grid-template-columns: 1fr 360px;
    grid-template-areas: 'title button' 'slider slider';
    gap: 80px 30px;
    .area_title{
      grid-area: title;
      .h2{
        max-width: 980px;
      }
    }
    .area_slider{
      grid-area: slider;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .area_button{
      grid-area: button;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .button{
        width: 100%;
      }
    }
  }
  .services_slider{
    max-width: 1285px;
    width: 100%;
    margin-right: 0;
  }
  .services_list{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
  }

  .swiper-initialized{
    .services_list{
      display: flex;
    }
  }

  .swiper-pagination{
    display: none;
  }

  @include breakpoint(xl){
    padding: 80px 0;
    &__grid{
      display: block;
      .area_title{
        margin-bottom: 40px;
      }
      .area_button{
        max-width: 360px;
        margin: 40px auto 0;
      }
    }
    .services_list{
      gap: 15px;
    }
  }

  @include breakpoint(md){
    .services_slider{
      margin:0 calc(var(--bs-gutter-x)* (-.5));
      width: calc(100% + var(--bs-gutter-x));
      padding: 0 calc(var(--bs-gutter-x)* .5);
    }
    .services_list{
      display: flex;
      gap: initial;
      &__item{
        max-width: 348px;
        width: 100%;
      }
      &__link{
        min-height: 220px;
      }
    }
    .swiper-pagination{
      display: block;
    }
  }

  @media (min-width: 768px){
    .services_list{
      transform: none!important;
    }
  }
}

/*------------------------*/
.index_certificate{
  padding: 80px 0;
  &__title{
    max-width: 780px;
  }
}

/*------------------------*/
.index_testimonials{
  padding: 180px 0;
  position: relative;
  &__title{
    margin-bottom: 80px;
  }
  .testimonials_slider{
    .swiper-pagination{
      display: none;
    }
  }

  @include breakpoint(lg){
    padding: 80px 0;
  }

  @include breakpoint(md){
    &__title{
      margin-bottom: 40px;
    }
    .testimonials_slider{
      .swiper-pagination{
        display: block;
      }
    }
    .swiper__nav{
      display: none;
    }
  }

  @include breakpoint(sm){
    .testimonials_list{
      &__item{
        &_title{
          flex-direction: column;
        }
      }
    }
  }
}

/*-------------------*/
.index_team{
  padding-top: 120px;
  padding-bottom: 120px;
  @include breakpoint(xl){
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.team_slider{
  &__nav{
    width: 100%;
    height: auto;
    padding: 30px 0;
    .swiper-scrollbar-horizontal{
      bottom: auto;
      top: 0;
    }
    .swiper-scrollbar-vertical{
      right: auto;
      left: 0;
    }
    &_item{
      border-radius: 14px;
      padding: 20px 30px;
      .subtitle{
        max-width: 250px;
        font-size: 1em;
      }
      .secondary_text_color{
        font-size: .9em;
      }
    }
    .swiper-slide{
      height: auto!important;
      display: flex;
      flex-direction: column;
      max-width: 280px;
      cursor: pointer;
    }
    .swiper-slide-thumb-active{
      cursor: initial;
      .team_slider__nav_item{
        background: var(--Light-Gray);
      }
    }
  }

  &__main{
    width: 100%;
    .single_team_title{
      padding: 0;
      &__desc{
        padding: 40px 30px;
        overflow-y: scroll;
        scrollbar-color: transparent transparent;
        scrollbar-width: thin;
        &::-webkit-scrollbar {
          width: 2px;
        }
        &::-webkit-scrollbar-track {
          display: none;
        }
      }
    }
  }

  @media (min-width: 1200px){
    .team_slider {
      &__nav {
        height: 620px;
        padding: 0 0 0 20px;
        .swiper-slide{
          max-width: 380px;
        }
      }
    }
  }

  @media (min-width: 1700px){
    .team_slider {
      &__nav {
        height: 707px;
      }
    }
  }

  @include breakpoint(lg){
    .team_slider {
      &__nav {
        margin:0 calc(var(--bs-gutter-x)* (-.5));
        width: calc(100% + var(--bs-gutter-x));
        padding: 20px calc(var(--bs-gutter-x)* .5);
        .swiper-slide{
          max-width: 225px;
        }
        &_item{
          padding: 20px 15px;
        }
      }
    }
    .single_team_title__img{
      max-width: 380px;
    }
  }

  @include breakpoint(md){
    &__main{
      .single_team_title{
        &__desc{
          padding: 2rem 1rem;
        }
      }
    }
  }
}








