.rotate_icon{
  width: 46px;
  height: 46px;
  vertical-align: middle;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  margin-left: 10px;
  margin-top: -10px;
  position: relative;
  svg{
    animation: rotating 2s linear infinite;
    width: 36px;
    height: 36px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @include breakpoint(lg){
    width: 30px!important;
    height: 30px!important;
    margin-top: -6px;
    svg{
      width: 20px!important;
      height: 20px!important;
    }
  }
}

@keyframes rotating {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}