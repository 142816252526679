.testimonials_list{
  &__item{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: auto;
    &:nth-child(4n+2){
      .quote{
        path{
          fill: var(--Brand-Color);
        }
      }
    }
    &:nth-child(4n+3){
      .quote{
        path{
          fill: var(--Brand-Green);
        }
      }
    }
    &:nth-child(4n+4){
      .quote{
        path{
          fill: var(--Brand-Pink);
        }
      }
    }
    .inner_wrap{
      border-radius: 30px;
      border: 1px solid var(--Border-Color);
      background: #FFF;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 30px 40px;
      transition: .5s all ease;
      min-height: 442px;
    }
    &_title{
      display: flex;
      gap: 60px;
      margin-bottom: 30px;
      .secondary_text_color{
        font-size: .9em;
      }
    }
    &_photo{
      width: 99px;
      min-width: 99px;
      height: 99px;
      border-radius: 16px;
      overflow: hidden;
      img{
        width: 100%;
        display: block;
      }
    }
  }

  @include breakpoint(xl){
    &__item{
      &_title{
        gap: 20px;
      }
    }
  }

  @include breakpoint(lg){
    &__item{
      .inner_wrap{
        padding: 20px;
        border-radius: 20px;
        min-height: 424px;
      }
      &_photo{
        width: 79px;
        min-width: 79px;
        height: 79px;
      }
    }
  }
}

