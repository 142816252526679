@mixin breakpoint($point) {
  @if $point == xs {
    @media (max-width: 360px) { @content; }
  }
  @else if $point == sm {
    @media (max-width: 575px) { @content; }
  }
  @else if $point == md {
    @media (max-width: 767px) { @content; }
  }
  @else if $point == lg {
    @media (max-width: 991px) { @content; }
  }
  @else if $point == xl {
    @media (max-width: 1199px) { @content; }
  }
  @else if $point == xxl {
    @media (max-width: 1700px) { @content; }
  }
}


@mixin image-hover-transform{
  transition: 0.5s all ease;
  overflow: hidden;
  &:hover{
    img{
      transform: scale(1.1);
    }
  }
  img{
    display: block;
    transition: 0.5s all ease;
    width: 100%;
  }
}