.single_service_hero_img{
  width: 100%;
  height: 558px;
  display: block;
  object-fit: cover;
  object-position: center;

  @include breakpoint(xxl){
    height: 458px;
  }

  @include breakpoint(lg){
    height: 282px;
  }
}

.single_service{
  padding-top: 180px;
  padding-bottom: 180px;
  .h2{
    max-width: 800px;
  }
  .price_table{
    tr{
      &:nth-child(even){
        background-color: #fff;
      }
      &:nth-child(odd){
        background-color: var(--Light-Gray);
      }
    }
  }

  .video_section{
    margin-bottom: 80px;
    .video_link{
      height: 563px;
      border-radius: 10px;
      &__icon{
        width: 204px;
        height: 204px;
      }
    }
  }

  .advantages_list{
    &__item{
      position: sticky;
      min-height: 245px;
    }
    @for $i from 1 through 8 {
      &__item:nth-child(#{$i}n) {
        top: $i * 80px;
      }
    }
  }

  @include breakpoint(xxl){
    padding-top: 100px;
    padding-bottom: 100px;
  }

  @include breakpoint(md){
    padding-top: 0;
    padding-bottom: 80px;
  }
}

.single_service_faq{
  padding: 120px 0 170px;
  .h2{
    max-width: 700px;
  }
  @include breakpoint(xl){
    padding: 80px 0;
  }
}

.faq{
  &__wrap{
    margin-bottom: 10px;
    border-radius: 20px;
    border: 2px solid var(--Border-Color);
    background: #FFF;
    padding: 0 40px;
  }
  .accordion-toggle{
    padding: 30px 40px 30px 0;
    position: relative;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.36px;
    text-transform: uppercase;
    .icon{
      content: '';
      width: 32px;
      height: 32px;
      border-radius: 50%;
      position: absolute;
      right:0;
      top: 50%;
      transform: rotate(0) translateY(-50%);
      display: block;
      transition: .3s all ease;
      border: 2px solid var(--Text-Color);
      &:before, &:after{
        content: '';
        background: var(--Text-Color);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50% ,-50%);
        width: 16px;
        height: 1.5px;
        transition: .3s all ease;
      }
      &:after{
        transform: translate(-50% ,-50%) rotate(90deg);
      }
    }
    &:hover, &.active{
      .icon{
        &:after{
          transform: translate(-50% ,-50%) rotate(0);
        }
      }
    }
  }
  .accordion-content{
    color: var(--Secondary-Text-Color);
    margin-bottom: 30px;
  }

  @include breakpoint(xl){
    &__wrap{
      padding: 0 20px;
      .accordion-toggle{
        padding: 20px 30px 20px 0;
        .icon{
          width: 24px;
          height: 24px;
          &:before, &:after{
            width: 12px;
          }
        }
      }
    }
  }
}