.breadcrumbs{
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  li{
    font-size: 0.9rem;
    line-height: 1.5;
    color: var(--Secondary-Text-Color);
    a{
      color: var(--Text-Color);
      transition: 0.5s all ease;
      position: relative;
      padding-right: 24px;
      line-height: 1.5;
      &:after{
        content: '/';
        width: 0;
        height: 0;
        position: absolute;
        top: 2px;
        right: 15px;
        color: var(--Secondary-Text-Color);
      }
      &:hover{
        text-decoration: underline;
      }
    }

  }
}
