:root{
  --Text-Color: #14041D;
  --Secondary-Text-Color: #848484;
  --Brand-Color: #DAA3EF;
  --Brand-Color-400: #A16ED4;
  --Brand-Color-200: #E3D3E9;
  --Brand-Color-100: #EBD9F2;
  --Brand-Green: #BECC94;
  --Brand-Blue: #A3D5FF;
  --Brand-Pink: #FFBFCE;
  --Dark-Brand: #805592;
  --Dark-Blue: #386274;
  --Dark-Green: #485B30;
  --Dark-Pink: #884A64;
  --Light-Gray: #F6F6F6;
  --Border-Color: #E9E9E9;
  --Brand-Blue-Light: #C6E5F3;
  --Brand-Green-Light: #DFEFCA;
  --Brand-Pink-Light: #FAD3E4;
}

body{
  font-family: 'Wix Madefor Display', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  background-color: #fff;
  color:var(--Text-Color);
  font-size: 1.125rem;
  @include breakpoint(xxl){
    font-size: 1rem;
  }
}

a{
  color: var(--Text-Color);
  display: inline-block;
  text-decoration: none;
  line-height: 1.5;
  &:hover{
    color: var(--Text-Color);
  }
}

p{
  line-height: 1.5;
  word-break: break-word;
}

b, strong {
  font-weight: bold;
}

.container{
  max-width: 1500px;
}

.light_gray_bg{
  background-color: var(--Light-Gray);
}

.brand_blue_light_bg{
  background-color: var(--Brand-Blue-Light);
}

.brand_green_light_bg{
  background-color: var(--Brand-Green-Light);
}

.main_color_bg{
  background-color: var(--Brand-Color-400);
  color: #fff;
  a{
    color: #fff;
  }
}

.main_color{
  color: var(--Brand-Color-400);
}
.secondary_text_color{
  color: var(--Secondary-Text-Color);
}





