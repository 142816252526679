.footer{
  font-size: 16px;
  &__wrap{
    padding: 80px 0;
    min-height: 570px;
  }
  &__desc{
    max-width: 271px;
  }
  &__sub{
    color:var(--Brand-Color-200);
    font-size: .9em;
  }
  &__nav{
    display: flex;
    align-items: center;
    gap: 82px;
    li{
      a{
        transition: .5s all ease;
        &:hover{
          color: var(--Brand-Color-400);
        }
      }
    }
    &_mobile{
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  @include breakpoint(xxl){
    &__wrap{
      min-height: 500px;
    }
  }

  @include breakpoint(xl){
    font-size: 14px;
    &__nav{
      gap: 30px;
    }
  }

  @include breakpoint(md){
    font-size: 13px;
  }

  @include breakpoint(sm){
    &__row{
      text-align: center;
      max-width: 230px;
      margin: 0 auto;
    }
    &__desc{
      margin: 0 auto;
    }
    .social{
      justify-content: center;
    }
  }
}