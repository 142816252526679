.swiper-pagination{
  position: relative;
  margin: 20px auto 0;
  top: auto;
  bottom: auto;
  left: auto;
  right: auto;
  &-bullet{
    background: var(--Border-Color);
    opacity: 1;
    margin: 0 2px;
    &-active{
      background: var(--Secondary-Text-Color);
    }
  }
}

.swiper__nav{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  .swiper-button-prev,
  .swiper-button-next{
    position: relative;
    left: auto;
    right: auto;
    top: auto;
    margin-top: 0;
    font-weight: 800;
  }
  @include breakpoint(lg){
    gap: 7px;
  }
}

.swiper-button-prev,
.swiper-button-next{
  color: #fff;
  width: 60px;
  height: 60px;
  background-color: var(--Brand-Color-400);
  border-radius: 50%;
  opacity: 0.6;
  &:after{
    content: '';
    background: url("../../assets/images/Union.svg")no-repeat;
    background-size: 100% 100%;
    width: 27px;
    height: 27px;
  }
  &:hover{
    opacity: 1;
  }
  @include breakpoint(lg){
    width: 32px;
    height: 32px;
    &:after{
      width: 16px;
      height: 16px;
    }
  }
}

.swiper-button-prev{
  &:after{
    transform: rotate(180deg);
  }
}

.swiper-scrollbar-drag{
  background: var(--Brand-Color-400);
}
.swiper-scrollbar{
  background: var(--Light-Gray);
}

.swiper-slide {
  display: flex;
  height: auto;
}

