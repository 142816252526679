.video_section{
  .video_link{
    height: 1080px;
    background: #E2E2E2;
    position: relative;
    display: block;
    @include image-hover-transform;
    &__icon{
      width: 366px;
      height: 366px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      svg{
        width: 100%;
        height: 100%;
      }
    }
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  @include breakpoint(xxl){
    .video_link{
      height: 750px;
      &__icon{
        width: 222px;
        height: 222px;
      }
    }
  }

  @include breakpoint(md){
    .video_link{
      height: 520px!important;
      &__icon{
        width: 150px!important;
        height: 150px!important;
      }
    }
  }
}