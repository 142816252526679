.accordion {
  .accordion-toggle {
    cursor: pointer;
    position: relative;
  }
  .accordion-content {
    display: none;
    &.active {
      display: block;
    }
  }
}